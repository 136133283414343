import { faReact, faHtml5, faSquareJs, faVk, faDiscord, faGithubAlt, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import  { useEffect, useState } from 'react';

const Title = () => {
  return(
    <>
    <h1 className="title_dev">
    Deamingo Dev
    </h1>
    </>
    )
};




const Stack = () => {
  const stackItems = useRef([]);

  const animateItem = (index) => {
    const item = stackItems.current[index];
    if (item) {
      // Устанавливаем начальные стили для анимации
      item.style.transition = 'transform 1s ease, opacity 1s ease';
      item.style.transform = 'translateY(100px)'; // Падение вниз
      item.style.opacity = '0.5'; // Уменьшаем непрозрачность

      // Возвращаем элемент на место через 1 секунду
      setTimeout(() => {
        item.style.transform = 'translateY(0)';
        item.style.opacity = '1';
      }, 1000);
    }
  };

  return (
    <div className="stackohz">
      <h1 className="stackTitle">Made on</h1>
      <div className="stackContainer">
        <FontAwesomeIcon
          icon={faReact}
          className="stack"
          ref={(el) => (stackItems.current[0] = el)}
          onClick={() => animateItem(0)}
        />
        <FontAwesomeIcon
          icon={faHtml5}
          className="stack"
          ref={(el) => (stackItems.current[1] = el)}
          onClick={() => animateItem(1)}
        />
        <FontAwesomeIcon
          icon={faSquareJs}
          className="stack"
          ref={(el) => (stackItems.current[2] = el)}
          onClick={() => animateItem(2)}
        />
      </div>
    </div>
  );
};

const Social = () => {
  return(
    <>
    <aside className="social-icon">
  <a
    target="_blank"
    className="social-a mrg_bt"
    href="https://discord.gg/BAduqPArD8"
  >
         <FontAwesomeIcon className="icon-ds mrg_bt" icon={faDiscord}/>
  </a>
  <a
    target="_blank"
    className="social-a mrg_bt"
    href="https://vk.com/id889856563"
  >
   <FontAwesomeIcon className="icon mrg_bt" icon={faVk}/>
  </a>
  <a
    target="_blank"
    className="social-a mrg_bt"
    href="https://t.me/mylifedigitized"
  >
   <FontAwesomeIcon className="icon mrg_bt" icon={faTelegram}/>
  </a>
  <a
    target="_blank"
    className="social-a imgit"
    href="https://github.com/keydrave"
  >
    <FontAwesomeIcon className="icon" icon={faGithubAlt}/>
  </a>
</aside>

    </>
    );
}

const TypingText = ({ text, speed }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let index = 0;

    const intervalId = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text.charAt(index));
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed]);

  return <div>{displayedText}</div>;
};


const Titlebottom = () => {
  return <h1 className="titlebottom"> <TypingText text="business card" speed={100}/> </h1>
}

const App = () => {
  
  return( <div>

    <Title/>
    <Stack/>
    <Social/>
    <Titlebottom/>

    </div>
    )
};

export default App;
